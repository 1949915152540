import { Component } from "react"
import Swiper from "swiper/dist/js/swiper"
import "swiper/dist/css/swiper.css"
import "./index.scss"

const source = [
    {
        list: ['index_logo_01_hzhb', 'index_logo_02_hzhb', 'index_logo_03_hzhb', 'index_logo_04_hzhb',
            'index_logo_05_hzhb', 'index_logo_06_hzhb', 'index_logo_07_hzhb', 'index_logo_08_hzhb', 'index_logo_09_hzhb']
    },
    { list: ['index_logo_10_hzhb'] }
];
class Partner extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        new Swiper('.swiper-container', {
            loop: true,
            speed: 300, // 页面切换间隔
            autoplay: 3000,//可选选项，自动滑动
            pagination : '.swiper-pagination',
        })
    }
    render() {
        return (
            <div className='home_partner' style={{ backgroundColor: this.props.bgColor }}>
                <h1>合作伙伴</h1>
                <div className="swiper-container">
                    <div className="swiper-wrapper">
                        {source.map((item, key) => (
                            <div key={key} className="swiper-slide">
                                <div className="swiper_row">
                                    {
                                        item.list.map((row, index) => (
                                            <img key={index} src={`image_m/home/parnter/${row}.png`} alt="" className="thum_opacity" />
                                        ))
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        )
    }
}

export default Partner;