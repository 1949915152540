import axios from 'axios'
import { Toast } from 'antd-mobile'

// const publicIp = process.env.NODE_ENV === 'development' ? 'https://gateway.woketest1.com' : 'https://gw.woketech.com'
const publicIp = process.env.REACT_APP_URL_API

export const request = (api, data, method = 'POST') => {
  let headers = {
    'content-type': 'application/json',
  }
  let options = { method: method, url: publicIp + api, headers }
  options = { ...options, [method == 'POST' ? 'data' : 'params']: data }
  return new Promise((resolve, reject) => {
    axios(options)
      .then((res) => {
        if (res.data.statusCode == 'B00000') {
          resolve(res.data)
        } else {
          Toast.show(res.data.message)
          reject(res)
        }
      })
      .catch((err) => {
        window.location.href = '/error'
        reject(err)
      })
  })
}
