import ApplyUse from '@/components/ApplyUse'

const list = [
  { name: '手机考勤', icon: 'wt-product_kq_icon01' },
  { name: '硬件考勤', icon: 'wt-product_kq_icon02' },
  { name: '考勤规则', icon: 'wt-product_kq_icon03' },
  { name: '假期管理', icon: 'wt-product_kq_icon04' },
  { name: '实时报表', icon: 'wt-product_kq_icon05' },
  { name: '智能分析', icon: 'wt-product_kq_icon06' },
]
const Attendance = () => {
  return (
    <div className='pro_detail'>
      <div className='part_one'>
        <h1>
          智慧 <span>考勤</span>
        </h1>
        <p>支持多种方式打卡，适配多地区劳动力管</p>
        <p>理制度，精准无误</p>
        <img
          src='/image_m/product/attendance/h5_kq_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 功能介绍 */}
      <div className='part_two'>
        <h1 className='title'>功能介绍</h1>
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              <p>{item.name}</p>
            </li>
          ))}
        </ul>
      </div>
      {/* 轻松管理 */}
      <div className='part_three'>
        <h1 className='title'>支持多种考勤方式</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>
              考勤系统支持手机在线打卡、以及对接考勤机（指纹、人脸识别）等多种打卡方式
            </p>
          </li>
          <li>
            <b></b>
            <p>多终端实时采集考勤数据，完整记录员工的每次打卡信息</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/attendance/h5_kq_chahua_02.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 组织架构多样化 */}
      <div className='part_four'>
        <h1 className='title'>适配多地区制度</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>支持同一组织架构内，多地区多种考勤制度并行，多组别差异化管理</p>
          </li>
          <li>
            <b></b>
            <p>轻松管理员工假期，根据考勤规则自动生成假期，智能分配调休假</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/attendance/h5_kq_chahua_03.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 一键生成 实时预警 */}
      <div className='part_three'>
        <h1 className='title'>一键生成 实时预警</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>考勤数据实时更新，一键生成轻松管理</p>
          </li>
          <li>
            <b></b>
            <p>分析报表及时同步，异常状况预警推送，高效助力决策</p>
          </li>
          <li>
            <b></b>
            <p>精准计算工时，关联薪酬核算</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/attendance/h5_kq_chahua_04.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
    </div>
  )
}
export default Attendance
