import './modal.scss'

const ImgModule = (props) => {
  const { close, name } = props
  const handleClose = () => {
    close()
  }
  return (
    <div className='modal'>
      <div className='img_mobal'>
        <div className='modal_body'>
          <div className='erweima'>
            <img
              src={`/image_m/home/woketech_img_erweima0${name}.png`}
              alt=''
            />
          </div>
          <p className='save'>长按/截图保存，微信识别二维码</p>
          <p>{name == 1 ? '或者关注梧桐People公众号' : '添加专属客服'}</p>
        </div>
        {/* 关闭按钮 */}
        <div className='modal_footer' onClick={handleClose}>
          <span>
            {' '}
            <svg className='icon close_btn' aria-hidden='true'>
              <use xlinkHref='#wt-woketech_icon_close'></use>
            </svg>
          </span>
        </div>
      </div>
    </div>
  )
}
export default ImgModule
