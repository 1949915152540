import { useState, useEffect } from 'react'
import './index.scss'
const descList = [
  {
    name: '智能',
    desc: '排班',
    frag: 'one',
    list: [
      { title: '智能排班引擎', icon: 'wt-index_kq_icon_01' },
      { title: 'AI预警', icon: 'wt-index_kq_icon_02' },
      { title: '驱动决策', icon: 'wt-index_kq_icon_03' },
    ],
  },
  {
    name: '智慧',
    desc: '考勤',
    frag: 'two',
    list: [
      { title: '多终端打卡', icon: 'wt-index_kq_icon_01' },
      { title: '假期管理', icon: 'wt-index_kq_icon_02' },
      { title: '流程管理', icon: 'wt-index_kq_icon_03' },
      { title: '考勤报表管理', icon: 'wt-index_kq_icon_04' },
    ],
  },
  {
    name: '薪资',
    desc: '管理',
    frag: 'three',
    list: [
      { title: '一键算薪', icon: 'wt-index_xc_icon_01' },
      { title: '薪税管理', icon: 'wt-index_xc_icon_02' },
      { title: '智能工资条', icon: 'wt-index_xc_icon_03' },
      { title: '闪电发薪', icon: 'wt-index_xc_icon_04' },
    ],
  },
  {
    name: '数据',
    desc: '仪表盘',
    frag: 'four',
    list: [
      { title: '考勤数据分析', icon: 'wt-index_pb_icon_01' },
      { title: '薪酬数据分析', icon: 'wt-index_sj_icon_02' },
      { title: '营收数据分析', icon: 'wt-index_sj_icon_03' },
      { title: '投入/产出分析', icon: 'wt-index_sj_icon_04' },
    ],
  },
  {
    name: '组织',
    desc: '人事',
    frag: 'five',
    list: [
      { title: '员工档案管理', icon: 'wt-index_rs_icon_01' },
      { title: '员工证件管理', icon: 'wt-index_rs_icon_02' },
      { title: '在线招聘', icon: 'wt-index_rs_icon_03' },
      { title: '数字化入职', icon: 'wt-index_rs_icon_04' },
    ],
  },
  {
    name: '企业',
    desc: '培训',
    frag: 'six',
    list: [
      { title: '在线培训', icon: 'wt-index_xt_icon_01' },
      { title: '课程打卡', icon: 'wt-index_pb_icon_02' },
      { title: '考试系统', icon: 'wt-index_xt_icon_03' },
    ],
  },
]
const Personnel = () => {
  const [currRow, setCurrRow] = useState(descList[0])
  useEffect(() => {
    //3秒循环
    const timer = setInterval(() => {
      const index = descList.findIndex((item) => item.frag == currRow.frag)
      setCurrRow(descList[index == 5 ? 0 : index + 1])
    }, 3000)
    return () => {
      clearInterval(timer)
    }
  })
  //定时器
  return (
    <div className='personnel'>
      <h1>梧桐People串联人事管理全流程</h1>
      <p className='desc_title'>真正帮助企业降本增效</p>
      {/* 动画操作 */}
      <div className='nav'>
        <img
          className='nav_bg'
          src='image_m/home/index_bj_yuanhuan.png'
          alt=''
        />
        <div className='nav_frag'>
          {descList.map((item, index) => (
            <div
              key={index}
              onClick={() => setCurrRow(item)}
              className={`box pos_${item.frag} ${
                currRow.frag === item.frag ? 'active' : ''
              }`}
            >
              <div className='box_con'>
                <p>
                  {item.name} <br />
                  {item.desc}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* 内容切换 */}
      <ul>
        {currRow.list.map((item, index) => (
          <li key={index}>
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref={`#${item.icon}`}></use>
            </svg>
            <p>{item.title}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default Personnel
