import ApplyUse from '@/components/ApplyUse'
const list = [
  { name: '组织架构', icon: 'wt-product_hr_icon01' },
  { name: '员工管理', icon: 'wt-product_hr_icon02' },
  { name: '档案管理', icon: 'wt-product_hr_icon03' },
  { name: '权限管理', icon: 'wt-product_hr_icon04' },
  { name: '员工画像', icon: 'wt-product_hr_icon05' },
  { name: '管理分析', icon: 'wt-product_hr_icon06' },
]
const Personnel = () => {
  return (
    <div className='pro_detail'>
      <div className='part_one'>
        <h1>
          组织 <span>人事</span>
        </h1>
        <p>“入、转、调、离”完成员工全生命周期一 </p>
        <p>站式在线管理</p>
        <img
          src='/image_m/product/personnel/h5_hr_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 功能介绍 */}
      <div className='part_two'>
        <h1 className='title'>功能介绍</h1>
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              <p>{item.name}</p>
            </li>
          ))}
        </ul>
      </div>
      {/* 轻松管理 */}
      <div className='part_three'>
        <h1 className='title'>入转调离 轻松管理</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>数字化入/离职，只需2分钟，无纸化管理一步到位</p>
          </li>
          <li>
            <b></b>
            <p>一键继承上任员工系统权限，帮助新人快速上岗</p>
          </li>
          <li>
            <b></b>
            <p>
              数字化管理员工成长时间轴，入职、转正、调岗、晋升、离职一键操作，完成员工全生命周期一站式在线管理
            </p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/personnel/h5_hr_chahua_02.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 组织架构多样化 */}
      <div className='part_four'>
        <h1 className='title'>组织架构多样化</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>自动生成复杂多层级组织架构，一键管理员工异动</p>
          </li>
          <li>
            <b></b>
            <p>内嵌多套组织架构，灵活满足企业快速多变的组织架构调整需求</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/personnel/h5_hr_chahua_03.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 高效管理员工档案 */}
      <div className='part_three'>
        <h1 className='title'>高效管理员工档案</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>全面记录员工信息，支持批量导入导出</p>
          </li>
          <li>
            <b></b>
            <p>可对员工档案、劳动关系进行全方位、深层次、多角度的查询与分析</p>
          </li>
          <li>
            <b></b>
            <p>
              可在微信公众号、PC端等多个端口远程录入人员资料，信息收集更便捷
            </p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/personnel/h5_hr_chahua_04.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
    </div>
  )
}
export default Personnel
