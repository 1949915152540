//新闻界面
export const newsList = '/backstage/open/api/news/newsList'
export const newsDetail = '/backstage/open/api/news/detail'
//合作伙伴界面
export const subAgent = '/backstage/open/api/clewAgent/add'
export const subService = '/backstage/open/api/clewProvider/add'
//全局留资提交
export const subGlobalForm = '/backstage/open/api/clewCustomer/add'
// 获取企微注册地址
// export const getRegistry = '/backstage/website/wechat/getRegister/code'
export const getRegistry = '/workchat/website/get/register/code'
// 安装地址
export const getInstall = '/workchat/website/get/install/url'
