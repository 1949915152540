import ApplyUse from '@/components/ApplyUse'

const list = [
  { name: '入职申请', icon: 'wt-product_gl_icon01' },
  { name: '转正申请', icon: 'wt-product_gl_icon02' },
  { name: '考勤申诉', icon: 'wt-product_gl_icon03' },
  { name: '报销申请', icon: 'wt-product_gl_icon04' },
  { name: '物品领用', icon: 'wt-product_gl_icon05' },
  { name: '数据共享', icon: 'wt-product_gl_icon06' },
  { name: '流程分析', icon: 'wt-product_gl_icon07' },
  { name: '运营管控', icon: 'wt-product_gl_icon08' },
  { name: '更多', icon: 'wt-h5_icon_more' },
]
const Manage = () => {
  return (
    <div className='pro_detail'>
      <div className='part_one'>
        <h1>
          OA <span>管理</span>
        </h1>
        <p>海量核心人事流程模版，企业定制化流程</p>
        <p>模版，随时随地协同处理</p>
        <img
          src='/image_m/product/manage/h5_gl_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 功能介绍 */}
      <div className='part_two'>
        <h1 className='title'>功能介绍</h1>
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              <p>{item.name}</p>
            </li>
          ))}
        </ul>
      </div>
      {/* 轻松管理 */}
      <div className='part_three'>
        <h1 className='title'>灵活配置 便捷设定</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>海量常规人事审批模版，请假、补卡、加班、出差等申请一键发起</p>
          </li>
          <li>
            <b></b>
            <p>自定义审批条件及流程，实现企业个性化需求定制</p>
          </li>
          <li>
            <b></b>
            <p>全线流程线上化，无纸化高效率</p>
          </li>
          <li>
            <b></b>
            <p>具备数据共享、流程分析、运营管控等功能，优化流程效率</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/manage/h5_gl_chahua_02.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 组织架构多样化 */}
      <div className='part_four'>
        <h1 className='title'>在线办公 协同处理</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>移动在线办公，随时随地一键发起</p>
          </li>
          <li>
            <b></b>
            <p>员工在线协同、智能催办，高效推进</p>
          </li>
          <li>
            <b></b>
            <p>自研无代码平台，“乐高式”的无门槛操作，简单易上手</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/manage/h5_gl_chahua_03.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
    </div>
  )
}
export default Manage
