import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './index.scss'

const navList = [
  {
    id: 0,
    name: '某便利店连锁经营业态',
    url: 'image_m/home/h5_index_chahua_10.png',
    tagList: [
      '考勤规则灵活多样',
      '准确预测经营 智能排班',
      '考勤数据完整准确',
      '实现工时成本最优化',
    ],
  },
  {
    id: 1,
    name: '某连锁超市外包业态',
    url: 'image_m/home/h5_index_chahua_11.png',
    tagList: [
      '核心人事数据互通',
      '员工一键确认申请',
      '减少HR90%手动操作',
      '信息、流程线上化',
    ],
  },
]
const Solution = () => {
  const [currRow, setCurrRow] = useState(navList[0])
  const navigate = useNavigate()

  const handleNavClick = (event) => {
    const index = event.target.getAttribute('row-type')
    if (!index) return
    setCurrRow(navList[index])
  }
  const toKnowMore = () => {
    navigate('/solution')
  }
  return (
    <div className='home_solution'>
      <h1>解决方案</h1>
      <ul className='solution_nav' onClick={handleNavClick}>
        {navList.map((item, index) => (
          <li
            key={index}
            row-type={index}
            className={index == currRow.id ? 'active' : ''}
          >
            {item.name}
            <div className='line'></div>
          </li>
        ))}
      </ul>
      {/* 内容展示区域 */}
      <div className='tag_list'>
        <div className='row_one'>
          <span className='color1'>{currRow.tagList[0]}</span>
          <span className='color2'>{currRow.tagList[1]}</span>
        </div>
        <div className='row_two'>
          <span className='color3'>{currRow.tagList[2]}</span>
          <span className='color4'>{currRow.tagList[3]}</span>
        </div>
      </div>
      <img src={currRow.url} alt='' />
      <button className='mainBtn' onClick={toKnowMore}>
        了解更多
        <svg className='icon' aria-hidden='true'>
          <use xlinkHref='#wt-woketech_icon_arrow_right'></use>
        </svg>
      </button>
    </div>
  )
}
export default Solution
