import React from 'react'
import FunTroduction from './components/FunTroduction'
import Solution from './components/Solution'
import Partner from './components/Partner'
import Personnel from './components/Personnel'
import FreeUse from './components/FreeUse'
import Header from '@/components/Header'
import Footer from '@/components/Footer'

import './index.scss'

export default class Home extends React.Component {
  render() {
    return (
      <div className='home' onScrollCapture={this.handleScroll}>
        <Header />
        <div className='content'>
          <div className='first_part'>
            <h1 className='title'>更简单就能用起来的员工管理系统</h1>
            <p className='title_son'>
              为企业提供全面高效的员工综合管理解决方案
            </p>
            <div className='icon_list'>
              <span>
                <img src='image_m/home/index_banner_icon_01.png' alt='' />
                闪电排班
              </span>
              <span>
                <img src='image_m/home/index_banner_icon_02.png' alt='' />
                灵活考勤
              </span>
              <span>
                <img src='image_m/home/index_banner_icon_03.png' alt='' />
                免费试用
              </span>
            </div>
            <img
              src='image_m/home/h5_index_chahua_01.png'
              alt=''
              className='first_img thum_opacity'
            />
          </div>
          {/* 认识管理全流程 */}
          <Personnel />
          {/* 功能介绍*/}
          <FunTroduction />
          {/* 我们的优势 */}
          <div className='four_part'>
            <h1>我们的优势</h1>
            <ul>
              <li>
                <img src='image_m/home/h5_index_chahua_07.png' alt='' />
                <div className='desc'>
                  <h2>灵活灵用</h2>
                  <p>
                    无代码平台，智能匹配复杂的组织及业务流操作简单，符合大众
                    用户使用习惯
                  </p>
                </div>
              </li>
              <li>
                <img src='image_m/home/h5_index_chahua_08.png' alt='' />
                <div className='desc'>
                  <h2>精准匹配</h2>
                  <p>多场景功能匹配</p>
                  <p>丰富的行业解决方案</p>
                </div>
              </li>
              <li>
                <img src='image_m/home/h5_index_chahua_09.png' alt='' />
                <div className='desc'>
                  <h2>性价比高</h2>
                  <p>
                    功能全面，涵盖人资管理各项板块，专业服务体系，注重客户体验
                  </p>
                </div>
              </li>
            </ul>
          </div>
          {/* 解决方案 */}
          <Solution />
          {/* 现有合作伙伴 */}
          <Partner bgColor='#fff' />
          {/* 免费使用 */}
          <FreeUse />
        </div>
      </div>
    )
  }
}
