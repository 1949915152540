import ApplyUse from '@/components/ApplyUse'

const list = [
  { name: '薪酬政策', icon: 'wt-product_xc_icon01' },
  { name: '绩效管理', icon: 'wt-product_xc_icon02' },
  { name: '薪酬发放', icon: 'wt-product_xc_icon03' },
  { name: '税务联通', icon: 'wt-product_xc_icon04' },
  { name: '个税申报', icon: 'wt-product_xc_icon05' },
  { name: '薪酬分析', icon: 'wt-product_xc_icon06' },
  { name: '电子工资条', icon: 'wt-product_xc_icon07' },
  { name: '员工管理', icon: 'wt-product_xc_icon08' },
  { name: '更多', icon: 'wt-h5_icon_more' },
]
const Payment = () => {
  return (
    <div className='pro_detail'>
      <div className='part_one'>
        <h1>
          薪资 <span>管理</span>
        </h1>
        <p>支持多种薪酬体系，多维度成本核算，高</p>
        <p>效解决企业“算薪难”问题</p>
        <img
          src='/image_m/product/payment/h5_xc_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 功能介绍 */}
      <div className='part_two'>
        <h1 className='title'>功能介绍</h1>
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              <p>{item.name}</p>
            </li>
          ))}
        </ul>
      </div>
      {/* 自动化算薪 */}
      <div className='part_three'>
        <h1 className='title'>自动化算薪</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>支持多套薪酬体系，自定义薪酬结构</p>
          </li>
          <li>
            <b></b>
            <p>匹配各地薪税政策，同步更新全国各地城市社保数据</p>
          </li>
          <li>
            <b></b>
            <p>支持最新个税专项附加扣除计算、劳务个税计算等</p>
          </li>
          <li>
            <b></b>
            <p>自动结合入、转、调、离及假勤等数据，避免手动输入</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/payment/h5_xc_chahua_02.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 多维数据 */}
      <div className='part_four'>
        <h1 className='title'>多维数据 精细管理</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>
              提供薪酬预算和发放的各维度数据动态表盘，帮助管理者全面掌握团队成员薪酬情况
            </p>
          </li>
          <li>
            <b></b>
            <p>
              智能分析薪酬报告，关联预算执行，多维度进行成本核算，实现精细化管理，驱动智慧决策
            </p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/payment/h5_xc_chahua_03.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 电子工资条一键推送 */}
      <div className='part_three'>
        <h1 className='title'>电子工资条一键推送</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>系统直接生成，告别纸质工资条</p>
          </li>
          <li>
            <b></b>
            <p>微信公众号、PC端等多个端口直接发送，安全保密</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/payment/h5_xc_chahua_04.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
    </div>
  )
}
export default Payment
