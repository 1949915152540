import { useEffect, useState } from 'react'
import { useLocation, Outlet } from 'react-router-dom'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import './index.scss'
import '@/styles/product.scss' //产品样式

const keyInfo = [
  {
    path: '/',
    title: '梧桐People - 员工管理系统_HR SaaS­_考勤管理系统_智能排班',
    keyWords:
      '考勤管理系统,排班系统,考勤系统,考勤平台,排班平台,人力资源管理,人事管理系统,工资计算系统,算薪发薪,灵活用工,闪电招聘,数字化转型,梧桐People,唤起云,唤行科技,唤醒科技',
    desc: '梧桐People是一款基于各类生态场景下开发的数字化HR SaaS产品，涵盖智能排班、人事管理、智慧考勤、算薪发薪、企业培训、闪电招聘、灵活用工等服务，为企业提供多维度人力资源管理支持，助力企业数字化转型。',
  },
  {
    path: '/product/personnel',
    title: '人事管理_人事管理系统_人事档案-梧桐People',
    keyWords: '人事管理系统,组织架构,员工管理,员工档案,电子合同',
    desc: '梧桐People人事，基于员工全生命周期，提供涵盖组织架构、员工管理、档案管理、权限管理、员工画像、管理分析等功能，帮助企业更好进行人力资源管理。',
  },
  {
    path: '/product/scheduling',
    title: '智能排班平台_排班系统_连锁行业排班-梧桐People',
    keyWords: '自动排班,智能排班,工时管理,排班系统',
    desc: '梧桐People排班，涵盖一键排班、智能排班、排班合规管理、经营预测、劳动力支援、排班动态分析等功能，帮助企业控制劳动力成本。',
  },
  {
    path: '/product/manage',
    title: '无纸化办公_人事流程_审批-梧桐People',
    keyWords: '人事流程,无纸化办公,审批流程,费用报销制度',
    desc: '梧桐People管理，涵盖入职申请、转正申请、考勤申诉、报销申请、物品领用、数据共享、流程分析、运营管控等功能，让企业实现流程线上化，无纸化办公。',
  },
  {
    path: '/product/attendance',
    title: '考勤管理系统_考勤系统_考勤表_假期管理-梧桐People',
    keyWords: '考勤管理系统,考勤系统,打卡',
    desc: '梧桐People考勤，支持手机考勤、硬件考勤等多种打卡方式，适配多地劳动力管理，精准无误。',
  },
  {
    path: '/product/payment',
    title: '薪酬管理系统_工资计算_电子工资条_薪酬管理-梧桐People',
    keyWords: '薪酬管理系统,工资计算,算薪发薪,个税申报,社保代缴',
    desc: '梧桐People薪酬，涵盖薪酬政策、绩效管理、薪酬发放、税务接口联通、个税一键申报、薪酬分析、电子工资条、员工价值管理等功能，支持多种薪酬体系，多维度成本核算，有效解决企业算薪难问题。',
  },
  {
    path: '/product/school',
    title: '企业培训_移动学习平台-梧桐People',
    keyWords: '企业培训系统,移动学习平台,线上培训平台',
    desc: '梧桐People学堂，涵盖在线考试、线上培训、社区互动等功能，为企业提供专业的企业培训系统。',
  },
  {
    path: '/product/isv',
    title: '企业微信应用—多场景签到工具',
    keyWords: '外勤、巡店、会议打卡工具，简单快速高效签到',
    desc: '适配外勤、巡店、会议、活动等多场景的实地，实景签到工具。',
  },
  {
    path: '/solution',
    title: '行业解决方案-梧桐People',
    keyWords: '排班系统,闪电招聘,人事管理系统,餐饮服务,连锁零售',
    desc: '梧桐People业务涵盖餐饮服务、连锁零售、生产制造、通用行业等，从人力资源管理角度，帮助企业降本增效。',
  },
  {
    path: '/partner',
    title: '合作伙伴-梧桐People',
    keyWords:
      '渠道合作,业务合作,人力资源管理,排班系统,考勤管理系统,薪酬管理系统',
    desc: '梧桐People打造“召唤联盟”，诚邀合作伙伴加入，共拓HR SaaS行业蓝海。',
  },
  {
    path: '/news',
    title: '资讯中心-梧桐People',
    keyWords: '梧桐People,梧桐People资讯,梧桐People动态',
    desc: '梧桐People资讯中心，实时更新梧桐People人事、排班、管理、考勤、薪酬、学堂等各类信息。',
  },
  // {
  //   path: '/aboutUs',
  //   title: '唤行科技_公司简介_关于梧桐People-梧桐People官网-梧桐PeopleHRSaaS',
  //   keyWords:
  //     '梧桐People,唤行科技,人力资源管理,排班系统,考勤管理系统,薪酬管理系统',
  //   desc: '梧桐People是一款基于各类生态场景下开发的数字化HR SaaS产品，涵盖智能排班、人事管理、智慧考勤、算薪发薪、企业培训、闪电招聘、灵活用工等服务，为企业提供多维度人力资源管理支持，助力企业数字化转型。',
  // },
]
const Layout = () => {
  const [showBack, setStatus] = useState(false)
  const [showHeaderFooter, setShowHeaderFooter] = useState(true)
  const { pathname, search } = useLocation()
  const target = document.getElementById('root')
  useEffect(() => {
    target.addEventListener('scroll', handleScroll)
    setShowHeaderFooter(!search.includes('hide'))
    return () => {
      target.removeEventListener('scroll', handleScroll)
    }
  })
  useEffect(() => {
    //重置滚动条
    backTop()
    if (pathname.indexOf('news') == -1) {
      sessionStorage.setItem('news_num', 1)
    }
    //动态修改关键词，标题信息
    const result = keyInfo.filter((item) => item.path == pathname)
    if (result.length === 0) return
    const { title, keyWords, desc } = result[0]
    document.title = title
    document
      .querySelector('meta[name="keyWords"]')
      .setAttribute('content', keyWords)
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', desc)
  }, [pathname])
  const handleScroll = () => {
    setStatus(target.scrollTop > 300)
  }
  const backTop = () => {
    target.scrollTop = 0
  }
  return (
    <div className='layput'>
      {showHeaderFooter && <Header></Header>}
      <div className='content'>
        <Outlet />
      </div>
      {showHeaderFooter && <Footer></Footer>}
      {/* 返回至顶部 */}
      {showBack ? (
        <span className='back_top' onClick={backTop}>
          <svg className='icon' aria-hidden='true'>
            <use xlinkHref='#wt-woketech_icon_xfk_hddb-copy'></use>
          </svg>
        </span>
      ) : null}
    </div>
  )
}
export default Layout
