import React from 'react'
import { request } from '../../../utils/request'
import { getRegistry, getInstall } from '../../../utils/api'

const list = [
  {
    name: '自由外勤',
    desc: '灵活办公新时代，拜访、巡店等外勤场景尽享自由签到之便。',
    color: '#008CF4',
    icon: 'wt-icon_zywq',
    sub_name: '适用于拜访、巡店等场景，支持选择范围内的地点',
    img: '/image_m/product/isv/product_gl_zywq.png',
  },
  {
    name: '结果可靠',
    desc: '防作弊水印保护，打卡结果可信；实时拍照，确保签到真实可靠。',
    color: '#67C239',
    icon: 'wt-icon_jgkk',
    sub_name: '支持现场拍照，照片水印、备注填写佐证签到结果',
    img: '/image_m/product/isv/product_gl_jgkk.png',
  },
  {
    name: '实时同步',
    desc: '签到结果轻松管理一览无余，便捷报表记录，助您快速掌握签到数据',
    color: '#F7BB33',
    icon: 'wt-icon_sstb',
    sub_name: '支持查看团队成员历史签到数据，一键导出签到结果报表',
    img: '/image_m/product/isv/product_gl_sstb.png',
  },
  {
    name: '活动会议',
    desc: '自定义打卡范国，扫码签到，准确记录签到者位置，让签到无忧！',
    color: '#5D70FF',
    icon: 'wt-icon_hdhy',
    sub_name: '适用于会议、活动等场景，扫码签到，更加精准确定签到者位置',
    img: '/image_m/product/isv/product_gl_hdhy.png',
  },
]

const School = () => {
  // 注册
  const handleRegistry = async () => {
    const { data } = await request(getRegistry, {}, 'POST')
    window.location.href =
      'https://open.work.weixin.qq.com/3rdservice/wework/register?register_code=' +
      data
  }
  // 安装
  const handleInstallation = () => {
    request(getInstall, {}, 'POST').then((res) => {
      const { data } = res
      window.location.href = data
    })
  }
  return (
    <div className='pro_detail'>
      <div className='part_isv_one'>
        <h1>适配多场景的签到工具</h1>
        <p>线上快速签到，拜访巡店、会议活动，轻松应对！</p>
        <p>签到结果即时生成，报表数据一目了然。</p>
        <div className='btns_box'>
          <img
            className='btn'
            src='https://open.work.weixin.qq.com/service/img?id=wwf9f75edb3c4ef20a&t=register&c=blue&s=medium'
            srcSet='https://open.work.weixin.qq.com/service/img?id=wwf9f75edb3c4ef20a&t=register&c=blue&s=medium@2x 2x'
            referrerPolicy='unsafe-url'
            alt='企业微信'
            onClick={handleRegistry}
          />
          <img
            className='btn'
            src='https://open.work.weixin.qq.com/service/img?id=wwf9f75edb3c4ef20a&t=install&c=white&s=medium'
            srcSet='https://open.work.weixin.qq.com/service/img?id=wwf9f75edb3c4ef20a&t=install&c=white&s=medium@2x 2x'
            referrerPolicy='unsafe-url'
            alt='企业微信'
            onClick={handleInstallation}
          />
        </div>
        <img
          src='/image_m/product/isv/partner_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      <div className='part_isv_two'>
        <h1 className='title'>功能特点</h1>
        <div className='sub_title'>我们给到的支持</div>
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <div className='iconbox' style={{ background: item.color }}>
                <svg className='icon' aria-hidden='true'>
                  <use xlinkHref={`#${item.icon}`}></use>
                </svg>
              </div>
              <div className='labelbox'>
                <p className='labelbox-title'>{item.name}</p>
                <p>{item.desc}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className='part_isv_three'>
        <ul>
          {list.map((item, index) => {
            return (
              <li key={index} className='desc_item'>
                <div className='desc_item_title'>{item.name}</div>
                <div className='desc_item_subtitle'>{item.sub_name}</div>
                <img src={item.img} alt='' />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
export default School
