import { useParams, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { newsDetail } from '@/utils/api'
import { request } from '@/utils/request'
import FreeUse from '@/pages/home/components/FreeUse'

const NewsDetail = () => {
  const [noticeInfo, setInfo] = useState({
    title: '',
    createTime: '',
    newsDetail: '',
  })
  const param = useParams()
  const location = useLocation()
  const [showUse, setShowUse] = useState(true)
  //初始化数据
  useEffect(() => {
    request(newsDetail, { id: param.id, url: window.location.href }, 'GET').then((result) => {
      if (result.statusCode == 'B00000') {
        const { title, keyWord, describes } = result.data
        setInfo(result.data)
        document.title = title
        document.querySelector('meta[name="keyWords"]').setAttribute('content', keyWord)
        document.querySelector('meta[name="description"]').setAttribute('content', describes)
      }
    })
    if (location.search.includes('hide')) {
      setShowUse(false)
    } else {
      setShowUse(true)
    }
  }, [])
  return (
    <>
      <div className='news_detail'>
        <h1 className='news_title'>{noticeInfo.title}</h1>
        <span className='news_time'>{noticeInfo.updateTime}</span>
        <div className='news_content' style={{ fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: noticeInfo.newsDetail }}></div>
      </div>
      {showUse && <FreeUse />}
    </>
  )
}

export default NewsDetail
