import React, { useImperativeHandle, useState } from 'react'
import { Toast } from 'antd-mobile'

import './index.scss'
import jsonData from '../../../../assets/json/city.json'

const navListOne = [
  {
    name: '超高收益',
    icon: 'wt-partner_dls_icon01',
    color: '#008CF4',
    text: '多项政策支持与商务支持，销售利润丰厚',
  },
  {
    name: '技术保障',
    icon: 'wt-partner_dls_icon02',
    color: '#67C239',
    text: '完善的产品与技术服务体系，提供快速迭代的功能，满足个性化需求',
  },
  {
    name: '培训支持',
    icon: 'wt-partner_dls_icon03',
    color: '#F7BB33',
    text: '成熟的培训体系，可提供业务支持和商务支持',
  },
  {
    name: '市场营销',
    icon: 'wt-partner_dls_icon04',
    color: '#008CF4',
    text: '提供行业沙龙、研讨会、行业展会等丰富多样的市场营销机会',
  },
  {
    name: '业务扶持',
    icon: 'wt-partner_dls_icon05',
    color: '#67C239',
    text: '渠道经理一对一帮扶代理商团队，及时响应各类需求',
  },
]
const navListTwo = [
  {
    icon: 'wt-partner_dls_icon06',
    color: '#008CF4',
    text: '认可梧桐People企业理念和商业模式，有长期合作的意向',
  },
  {
    icon: 'wt-partner_dls_icon07',
    color: '#F7BB33',
    text: '具备公司资质，有合法营业执照和独立法人代表，有良好商业信誉和口碑',
  },
  {
    icon: 'wt-partner_dls_icon08',
    color: '#67C239',
    text: '具备To B的获客渠道和行业资源',
  },
  {
    icon: 'wt-partner_dls_icon09',
    color: '#008CF4',
    text: '有良好的客户服务意识，有专属且成熟的销售团队',
  },
]
const Agent = (props) => {
  useImperativeHandle(props.onRef, () => {
    return {
      resetForm: resetForm,
    }
  })
  const [cityOptions, setCityOptions] = useState([])
  const [formData, setData] = useState({
    name: '',
    phone: '',
    email: '',
    corpName: '',
    cityIntention: '',
    city: '',
    province: '',
  })
  const resetForm = () => {
    setData({
      name: '',
      phone: '',
      email: '',
      corpName: '',
      cityIntention: '',
      city: '',
      province: '',
    })
    setCityOptions([])
  }
  const { submitCallback } = props
  const RegExpPhoneNum = /^1[3456789]\d{9}$/u //手机号

  const handleSubmit = () => {
    //参数校验
    let { name, phone, cityIntention, city } = formData
    if (name == '') {
      Toast.show('请输入申请人姓名')
      return
    }
    if (!RegExpPhoneNum.test(phone)) {
      Toast.show('请输入正确的手机号')
      return
    }
    //获取省市名称  cityIntention + ''
    const res = cityOptions.filter((item) => item.cid == city)
    if (res.length > 0) {
      cityIntention += res[0].name
    }
    submitCallback({ ...formData, cityIntention: cityIntention })
  }
  const handleChange = (event) => {
    const target = { ...formData, [event.target.name]: event.target.value }
    setData(target)
  }
  const prevent = (event) => {
    event.preventDefault()
  }
  const handleProvince = (event) => {
    let pid = event.target.value
    let target = jsonData.filter((item) => item.pid == pid)
    let targetCity = target[0].children
    setCityOptions(targetCity)
    const targetForm = {
      ...formData,
      cityIntention: target[0].name,
      city: targetCity[0].cid,
      province: pid,
    }
    setData(targetForm)
  }
  return (
    <div className='agent'>
      <div className='agent_service_one'>
        <h1 className='title'>代理商</h1>
        <p className='title_desc'>梧桐People给到的支持</p>
        <ul>
          {navListOne.map((item, index) => (
            <li key={index}>
              <span style={{ backgroundColor: item.color }}>
                <svg className='icon' aria-hidden='true'>
                  <use xlinkHref={`#${item.icon}`}></use>
                </svg>
              </span>
              <div className='desc'>
                <h1>{item.name}</h1>
                <p>{item.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className='agent_two'>
        <h1 className='title'>代理商要求</h1>
        <ul>
          {navListTwo.map((item, index) => (
            <li key={index}>
              <span style={{ backgroundColor: item.color }}>
                <svg className='icon' aria-hidden='true'>
                  <use xlinkHref={`#${item.icon}`}></use>
                </svg>
              </span>
              <p>{item.text}</p>
            </li>
          ))}
        </ul>
        {/* 图片展示 */}
        <img
          src='image_m/parnter/h5_partner_chahua_02.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 联系合作 */}
      <div className='agent_service_form'>
        <h1 className='title'>联系合作</h1>
        <p className='tips'>
          请填写真实信息，我们的工作人员将在1-2个工作日内联系您
        </p>
        <form onSubmit={prevent}>
          <label htmlFor='name'>
            申请人姓名 <span>*</span>
          </label>
          <input
            type='text'
            style={{ borderWidth: '1px' }}
            name='name'
            maxLength={10}
            value={formData.name}
            onChange={handleChange}
            placeholder='请输入姓名'
          />
          <label htmlFor='phone'>
            联系电话 <span>*</span>
          </label>
          <input
            type='text'
            style={{ borderWidth: '1px' }}
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            placeholder='请输入手机号'
          />
          <label htmlFor='email'>联系邮箱</label>
          <input
            type='text'
            style={{ borderWidth: '1px' }}
            name='email'
            maxLength={20}
            value={formData.email}
            onChange={handleChange}
            placeholder='请输入邮箱'
          />
          <label htmlFor='corpName'>公司名称</label>
          <input
            type='text'
            style={{ borderWidth: '1px' }}
            name='corpName'
            maxLength={20}
            value={formData.corpName}
            onChange={handleChange}
            placeholder='请输入公司名称'
          />
          <label htmlFor='city'>意向代理城市</label>
          <select
            name='province'
            style={{ borderWidth: '1px' }}
            onChange={handleProvince}
            value={formData.province}
            placeholder='请选择省'
          >
            <option value=''>请选择省</option>
            {jsonData.map((item) => {
              return (
                <option key={item.pid} value={item.pid}>
                  {item.name}
                </option>
              )
            })}
          </select>
          <select
            name='city'
            style={{ borderWidth: '1px' }}
            onChange={handleChange}
            value={formData.city}
            placeholder='请选择市'
          >
            <option value=''>请选择市</option>
            {cityOptions.map((item) => {
              return (
                <option key={item.cid} value={item.cid}>
                  {item.name}
                </option>
              )
            })}
          </select>
          <button className='mainBtn' type='submit' onClick={handleSubmit}>
            提交
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#wt-woketech_icon_arrow_right'></use>
            </svg>
          </button>
        </form>
      </div>
    </div>
  )
}
export default Agent
