import { useState, useImperativeHandle } from 'react'
import { Toast } from 'antd-mobile'

import './index.scss'
const navListOne = [
  {
    name: '市场',
    icon: 'wt-partner_fws_icon01-copy',
    color: '#008CF4',
    text: 'HR-SaaS 市场蓝海广阔，企业数字化转型势不可挡',
  },
  {
    name: '产品',
    icon: 'wt-partner_fws_icon021-copy',
    color: '#67C239',
    text: '梧桐People产品技术夯实，专业研发团体，专注HR-SaaS行业拓展和开发',
  },
  {
    name: '资源',
    icon: 'wt-partner_fws_icon031-copy',
    color: '#F7BB33',
    text: '共享行业人脉、商业机会，携手共进',
  },
]
const Service = (props) => {
  useImperativeHandle(props.onRef, () => {
    return {
      resetForm: resetForm,
    }
  })
  const [formData, setData] = useState({
    name: '',
    phone: '',
    corpName: '',
  })
  const { submitCallback } = props
  const RegExpPhoneNum = /^1[3456789]\d{9}$/u //手机号

  const handleSubmit = () => {
    //参数校验
    let { name, phone } = formData
    if (name == '') {
      Toast.show('请输入申请人姓名')
      return
    }
    if (!RegExpPhoneNum.test(phone)) {
      Toast.show('请输入正确的手机号')
      return
    }
    submitCallback(formData, 'service')
  }
  const handleChange = (event) => {
    const target = { ...formData, [event.target.name]: event.target.value }
    setData(target)
  }
  const resetForm = () => {
    setData({
      name: '',
      phone: '',
      corpName: '',
    })
  }
  const prevent = (event) => {
    event.preventDefault()
  }
  return (
    <div className='service'>
      <div className='agent_service_one'>
        <h1 className='title'>服务商</h1>
        <p className='title_desc'>强强联合 共创未来</p>
        <ul>
          {navListOne.map((item, index) => (
            <li key={index}>
              <span style={{ backgroundColor: item.color }}>
                <svg className='icon' aria-hidden='true'>
                  <use xlinkHref={`#${item.icon}`}></use>
                </svg>
              </span>
              <div className='desc'>
                <h1>{item.name}</h1>
                <p>{item.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* 合作形式 */}
      <div className='service_two'>
        <h1 className='title'>合作形式</h1>
        <div className='feature'>
          <span>
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#wt-partner_fws_icon04'></use>
            </svg>
          </span>
          <p>产品联合共建，丰富产品形态</p>
        </div>
        <div className='feature oth'>
          <span>
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#wt-partner_fws_icon05'></use>
            </svg>
          </span>
          <p>联手丰富行业解决方案，高效精准触达客户</p>
        </div>
        <img
          src='image_m/parnter/h5_partner_chahua_03.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 表单 */}
      <div className='agent_service_form'>
        <h1 className='title'>联系合作</h1>
        <p className='tips'>
          请填写真实信息，我们的工作人员将在1-2个工作日内联系您
        </p>
        <form onSubmit={prevent}>
          <label htmlFor='name'>
            申请人姓名 <span>*</span>
          </label>
          <input
            type='text'
            style={{ borderWidth: '1px' }}
            name='name'
            maxLength={10}
            value={formData.name}
            onChange={handleChange}
            placeholder='请输入姓名'
          />
          <label htmlFor='phone'>
            联系电话 <span>*</span>
          </label>
          <input
            type='text'
            style={{ borderWidth: '1px' }}
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            placeholder='请输入手机号'
          />
          <label htmlFor='corpName'>公司名称</label>
          <input
            type='text'
            style={{ borderWidth: '1px' }}
            name='corpName'
            maxLength={20}
            value={formData.corpName}
            onChange={handleChange}
            placeholder='请输入公司名称'
          />
          <button className='mainBtn' type='submit' onClick={handleSubmit}>
            提交
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#wt-woketech_icon_arrow_right'></use>
            </svg>
          </button>
        </form>
      </div>
    </div>
  )
}
export default Service
