import FreeUse from '../home/components/FreeUse'
import './index.scss'

const oneList = [
  {
    name: '餐饮服务',
    icon: 'wt-solution_icon_01',
    color: 'rgb(0,140,244,0.60)',
    bgColor: 'rgba(0,140,244,0.06)',
  },
  {
    name: '生产制造',
    icon: 'wt-solution_icon_03',
    color: 'rgb(255,90,75,0.60)',
    bgColor: 'rgba(255,101,79,0.06)',
  },
  {
    name: '连锁零售',
    icon: 'wt-solution_icon_02',
    color: 'rgb(103,194,57,0.60)',
    bgColor: 'rgba(103,194,57,0.06)',
  },
  {
    name: '通用解决方案',
    icon: 'wt-solution_icon_04',
    color: 'rgb(98,66,235,0.60)',
    bgColor: 'rgba(98,66,235,0.06)',
  },
]
const twoList = [
  {
    name: '智能排班引擎',
    url: 'h5_solution_chahua_02',
    text: '根据天气状况、节假日、政策管控等多种客观因素，预判客流峰谷期，提供科学合理的排班方案，帮助管理者提高排班管理效率，合理规划劳动力使用效率，提高企业用人效率，减少企业管理成本。',
  },
  {
    name: '排班动态分析',
    url: 'h5_solution_chahua_03',
    text: '餐饮零售行业，通常一天之内多个班次的情况，系统准确记录并判断各岗位员工排班时间是否相对科学和平衡，避免出现员工过度疲劳或闲置。保障安全生产、有序作业，也有助于提高员工满意度和归属感，降低员工离职率。',
  },
  {
    name: '劳动力支援',
    url: 'h5_solution_chahua_04',
    text: '“抢班”、“跨店支援”、“闪电招聘”等功能，支 持门店在人力不足的情况下，迅速调配员工资源，及时补充 劳动力缺口，保证门店经营工作正常有序地开展。',
  },
]
const threeList = [
  {
    name: '开店选址服务',
    url: 'h5_solution_chahua_05',
    text: '多维度的大数据科学分析客户的数据报告。提供选址目的地大范围内的居住人群分析/每周的客流人群分析/客流画像/购买力/客单价/同行竞争力等，用有效且科学可视化的数据作为客观的选址依据供客户快速筛查和参考。',
  },
  {
    name: '招聘交付服务',
    url: 'h5_solution_chahua_06',
    text: '根据客户提供的素质模型，我们通过专属人才渠道，准确快速定位到需要的人才信息，为企业快速扩张提供人力基础。餐饮和零售行业的人员流动相对较快，稳定时也需要有一定的人才储备，以备不时之需。',
  },
  {
    name: '快速入离职',
    url: 'h5_solution_chahua_07',
    text: '员工入职，只需通过智能手机，就能办理入职信息提交和劳 动合同签署，完成后自动推送新员工须知、相关培训课程及 考试验收安排。当有大量人员频繁进出时，该项功能能够帮 助门店经理/HR节省大量的时间和精力。对于门店遍布全国 的企业，大大减少了劳动合同寄回盖章的成本。',
  },
]
const fourList = [
  {
    name: '人才培训系统',
    url: 'h5_solution_chahua_08',
    text: '为企业提供移动学习、培训管理的线上培训平台，HR可快 速搭建新员工培训专题，学习内容整合，标准培训可复用； 更新课件可实时提交，周知员工按时学习；对于某些需要掌 握要求比较高的知识，在线考试系统可以验证员工对重点知 识的掌握程度。',
  },
  {
    name: '排班合规管理',
    url: 'h5_solution_chahua_09',
    text: '工厂制造业对用工合规性和生产安全精细化管理要求较高， 该功能充分结合了劳动力管理相关的法律法规，制定了排班 人员、排班时间、排班周期和频次相应规则，通过疲劳度和 用工安全预警、特殊岗位资质证明合规提醒等，规避了安全 生产中的风险隐患，使用最少人力成本，实现企业利润最大 化。',
  },
]
const Solution = () => {
  return (
    <div className='solution'>
      {/* 行业解决方案 */}
      <div className='part_one'>
        <h1>行业解决方案</h1>
        <ul>
          {oneList.map((item, index) => (
            <li
              key={index}
              style={{
                borderColor: item.color,
                color: item.color,
                backgroundColor: item.bgColor,
                borderWidth: '1px',
              }}
            >
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              {item.name}
            </li>
          ))}
        </ul>
        <img
          src='image_m/solution/h5_solution_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 大型餐饮/零售企业案例 */}
      <div className='part_two'>
        <h1 className='title'>大型餐饮/零售企业案例</h1>
        <ul>
          {twoList.map((item, index) => (
            <li key={index}>
              <img
                src='image_m/solution/h5_solution_icon_05.png'
                alt=''
                className='icon_left'
              />
              <div className='right'>
                <h1>{item.name}</h1>
                <p>{item.text}</p>
                <div style={{ textAlign: 'center' }}>
                  <img src={`image_m/solution/${item.url}.png`} alt='' />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* 新兴餐饮/零售行业案例 */}
      <div className='part_three'>
        <h1 className='title'>新兴餐饮/零售行业案例</h1>
        <ul>
          {threeList.map((item, index) => (
            <li key={index}>
              <img
                src='image_m/solution/h5_solution_icon_05_bule.png'
                alt=''
                className='icon_left'
              />
              <div className='right'>
                <h1>{item.name}</h1>
                <p>{item.text}</p>
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={`image_m/solution/${item.url}.png`}
                    alt=''
                    className='thum_opacity'
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* 物流/制造业案例 */}
      <div className='part_two'>
        <h1 className='title'>物流/制造业案例</h1>
        <ul>
          {fourList.map((item, index) => (
            <li key={index}>
              <img
                src='image_m/solution/h5_solution_icon_05.png'
                alt=''
                className='icon_left'
              />
              <div className='right'>
                <h1>{item.name}</h1>
                <p>{item.text}</p>
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={`image_m/solution/${item.url}.png`}
                    alt=''
                    className='thum_opacity'
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <FreeUse></FreeUse>
    </div>
  )
}
export default Solution
