import './index.scss'

const ErrorPage = () => {
  return (
    <div className='not_found'>
      <img src={'/image_m/h5_img_500.png'} alt='' />
    </div>
  )
}
export default ErrorPage
