import { useState, useEffect } from 'react'
import { request } from '@/utils/request'
import { subGlobalForm } from '@/utils/api'
import { Toast } from 'antd-mobile'
import './modal.scss'

const HomeModal = (props) => {
  const [formData, setData] = useState({
    name: '',
    phone: '',
    corpName: '',
    channel: 1,
  })
  const { close } = props

  const handleChange = (event) => {
    const target = { ...formData, [event.target.name]: event.target.value }
    setData(target)
  }
  const RegExpPhoneNum = /^1[3456789]\d{9}$/u //手机号
  const handleSubmit = () => {
    //提交
    const { name, phone, corpName } = formData
    if (name == '') {
      Toast.show('请输入申请人姓名')
      return
    }
    if (!RegExpPhoneNum.test(phone)) {
      Toast.show('请输入正确的手机号')
      return
    }
    if (corpName == '') {
      Toast.show('请输入公司名称')
      return
    }
    request(subGlobalForm, { ...formData }).then((result) => {
      if (result.statusCode == 'B00000') {
        Toast.show('您的资料已成功提交！')
        close()
      } else {
        Toast.show(result.message)
      }
    })
  }
  const handleClose = () => {
    //关闭
    close()
  }
  const prevent = (event) => {
    event.preventDefault()
  }
  return (
    <>
      <div className='modal '>
        <div className='home_modal'>
          <div className='modal_body'>
            <h1>信息填写</h1>
            {/* 表单 */}
            <form onSubmit={prevent}>
              <label htmlFor='name'>
                申请人姓名 <span>*</span>
              </label>
              <input
                style={{ borderWidth: '1px' }}
                type='text'
                name='name'
                maxLength={10}
                onChange={handleChange}
                placeholder='请输入姓名'
              />
              <label style={{ borderWidth: '1px' }} htmlFor='phone'>
                联系电话 <span>*</span>
              </label>
              <input
                style={{ borderWidth: '1px' }}
                type='text'
                name='phone'
                onChange={handleChange}
                placeholder='请输入手机号'
              />
              <label htmlFor='corpName'>
                公司名称 <span>*</span>
              </label>
              <input
                style={{ borderWidth: '1px' }}
                type='text'
                name='corpName'
                maxLength={20}
                onChange={handleChange}
                placeholder='请输入公司名称'
              />
              <button className='mainBtn' type='submit' onClick={handleSubmit}>
                提交
                <svg className='icon' aria-hidden='true'>
                  <use xlinkHref='#wt-woketech_icon_arrow_right'></use>
                </svg>
              </button>
            </form>
            <p className='tips_msg'>
              请填写真实信息，工作人员将在1-2个工作日内联系您。
            </p>
          </div>
          {/* 关闭按钮 */}
          <div className='modal_footer' onClick={handleClose}>
            <span>
              {' '}
              <svg className='icon close_btn' aria-hidden='true'>
                <use xlinkHref='#wt-woketech_icon_close'></use>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
export default HomeModal
