import ApplyUse from '@/components/ApplyUse'

const list = [
  { name: '在线课程', icon: 'wt-product_xt_icon01' },
  { name: '移动学习', icon: 'wt-product_xt_icon02' },
  { name: '培训管理', icon: 'wt-product_xt_icon03' },
  { name: '培训系统', icon: 'wt-product_xt_icon04' },
  { name: '在线考试', icon: 'wt-product_xt_icon05' },
  { name: '新员工资讯', icon: 'wt-product_xt_icon06' },
]
const School = () => {
  return (
    <div className='pro_detail'>
      <div className='part_one'>
        <h1>
          企业 <span>培训</span>
        </h1>
        <p>1 分钟搞定新员工培训</p>
        <p>3 分钟搭建培训体系</p>
        <img
          src='/image_m/product/school/h5_xt_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 功能介绍 */}
      <div className='part_two'>
        <h1 className='title'>功能介绍</h1>
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              <p>{item.name}</p>
            </li>
          ))}
        </ul>
      </div>
      {/* 1 分钟搞定新员工培训 */}
      <div className='part_three'>
        <h1 className='title'>1 分钟搞定新员工培训</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>
              HR可快速搭建新员工培训专题： <br /> 学习内容整合，标准培训可复用
            </p>
          </li>
          <li>
            <b></b>
            <p>
              学习路径规划：
              <br /> 打造新员工从入司到转正的学习路径
            </p>
          </li>
          <li>
            <b></b>
            <p>
              学习效果评估：
              <br /> 通过考试和数据评估新员工学习效果
            </p>
          </li>
          <li>
            <b></b>
            <p>
              丰富新员工资讯： <br />
              通过各种消息推送，帮助新员工增加认知、快速融入
            </p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/school/h5_xt_chahua_02.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 3 分钟搭建培训体系 */}
      <div className='part_four'>
        <h1 className='title'>3 分钟搭建培训体系</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>海量岗位培训学习体系，丰富课程资源，支持企业基础培训需求</p>
          </li>
          <li>
            <b></b>
            <p>
              针对不同部门、不同员工建立对应的学习计划，帮助企业迅速搭建培训体系
            </p>
          </li>
          <li>
            <b></b>
            <p>企业培训标准化、专业化运作，让你不再为培训规划而发愁</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/school/h5_xt_chahua_03.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
    </div>
  )
}
export default School
