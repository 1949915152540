import { useState } from "react";
import HomeModal from "@/components/Modal/HomeModal";
const ApplyUse = () => {
    const [isShow, setShow] = useState(false);
    const handleToggle = () => {
        setShow(!isShow);
    }
    return (
        <>
            <button className="mainBtn" onClick={handleToggle}>申请试用
                <svg className="icon" aria-hidden="true">
                    <use xlinkHref="#wt-woketech_icon_arrow_right"></use>
                </svg>
            </button>
            {/* 弹窗 */}
            {
                isShow ? <HomeModal close={handleToggle} /> : null
            }
        </>
    )
}
export default ApplyUse