import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from '@/pages/layout'
import Home from '@/pages/home/home'
import Personnel from '@/pages/product/personnel'
import Manage from '@/pages/product/manage'
import Attendance from '@/pages/product/attendance'
import Payment from '@/pages/product/payment'
import School from '@/pages/product/school'
import Scheduling from '@/pages/product/scheduling'
import Isv from '@/pages/product/isv'
import Solution from '@/pages/solution'
import Partner from '@/pages/partner'
import AboutUs from '@/pages/about'
import News from '@/pages/news'
import NewsDetail from '@/pages/news/detail'
import NotFoundPage from '@/pages/error/404'
import ErrorPage from '@/pages/error/500'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='' element={<Layout />}>
          <Route path='/' element={<Home />}></Route>
          <Route path='solution' element={<Solution />}></Route>
          <Route path='partner' element={<Partner />}></Route>
          {/* <Route path='aboutUs' element={<AboutUs />}></Route> */}
          {/* <Route path='news' element={<News />}></Route> */}
          <Route path='news/:id' element={<NewsDetail />}></Route>
          <Route path='/product'>
            <Route path='personnel' element={<Personnel />}></Route>
            <Route path='manage' element={<Manage />}></Route>
            <Route path='attendance' element={<Attendance />}></Route>
            <Route path='payment' element={<Payment />}></Route>
            <Route path='school' element={<School />}></Route>
            <Route path='scheduling' element={<Scheduling />}></Route>
            <Route path='isv' element={<Isv />}></Route>
          </Route>
          <Route path='/error' element={<ErrorPage />}></Route>
          <Route path='*' element={<NotFoundPage />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
export default Router
