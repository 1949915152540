import { useState } from 'react'
import HomeModal from '@/components/Modal/HomeModal'
import './index.scss'
const FreeUse = () => {
  const [isShow, setShow] = useState(false)
  const handleToggle = () => {
    setShow(!isShow)
  }
  return (
    <div className='free_use'>
      <h1 className='title'>免费使用</h1>
      {/* 图标文字 */}
      <img
        src='/image_m/user_login_logo_freeuse.png'
        alt='logo'
        className='icon logo'
      />
      <p className='title_son'>企业提升人效 首选梧桐People</p>
      <button className='mainBtn' onClick={handleToggle}>
        申请试用
        <svg className='icon' aria-hidden='true'>
          <use xlinkHref='#wt-woketech_icon_arrow_right'></use>
        </svg>
      </button>
      {/* 弹窗 */}
      {isShow ? <HomeModal close={handleToggle} /> : null}
    </div>
  )
}
export default FreeUse
