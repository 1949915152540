import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tabs } from 'antd-mobile'

import './index.scss'

const funList = [
  {
    id: 0,
    name: '组织人事',
    title: '人事管理 无后顾之忧',
    url: 'image_m/home/h5_index_chahua_02.png',
    path: '/product/personnel',
    type: 1,
    iconList: [
      { icon: 'wt-a-index_icon_01_rs', text: '入职' },
      { icon: '', text: '转正' },
      { icon: '', text: '调岗' },
      { icon: '', text: '离职' },
    ],
    litterTitle: '完成员工全生命周期一站式在线管理',
  },
  {
    id: 1,
    name: '智能排班',
    title: '智能排班 省心又省力',
    url: 'image_m/home/h5_index_chahua_03.png',
    path: '/product/scheduling',
    type: 2,
    iconList: [
      { icon: 'wt-a-index_icon_01_pb', text: '结合AI技术精确预测工时' },
      { icon: 'wt-a-index_icon_02_pb', text: '合理规划劳动力' },
    ],
    litterTitle: '帮助企业控制劳动力成本，提高用工效率',
  },
  {
    id: 2,
    name: '智慧考勤',
    title: '智慧考勤 一键唤醒',
    url: 'image_m/home/h5_index_chahua_04.png',
    path: '/product/attendance',
    type: 2,
    iconList: [{ icon: 'wt-a-index_icon_01_kq', text: '支持多种方式打卡' }],
    litterTitle: '适配多地区劳动力管理制度，精准无误',
  },
  {
    id: 3,
    name: '薪资管理',
    title: '薪资管理 精准无误',
    url: 'image_m/home/h5_index_chahua_05.png',
    path: '/product/payment',
    type: 2,
    iconList: [
      {
        icon: 'wt-a-index_icon_01_xc',
        text: '支持多种薪酬体系 多维度成本核算',
      },
    ],
  },
  {
    id: 4,
    name: '企业培训',
    title: '企业培训 便捷高效',
    url: 'image_m/home/h5_index_chahua_06.png',
    path: '/product/school',
    type: 2,
    iconList: [
      { icon: 'wt-a-index_icon_01_px', text: '1分钟搞定新员工培训' },
      { icon: 'wt-a-index_icon_02_px', text: '3分钟搭建培训体系' },
    ],
  },
]
const FunTroduction = (props) => {
  const [currRow, setCurrRow] = useState(funList[0])
  const navigate = useNavigate()

  const handleNavClick = (event) => {
    const index = event.target.getAttribute('row-type')
    if (!index) return
    setCurrRow(funList[index])
  }
  const toKnowMore = () => {
    navigate(currRow.path)
  }
  const changeTab = (index) => {
    setCurrRow(funList[index])
  }
  return (
    <div className='fun_troduction'>
      <h1>功能介绍</h1>
      <div className='fun_box hideScroll'>
        <Tabs onChange={changeTab} activeLineMode='fixed'>
          {funList.map((item, index) => (
            <Tabs.Tab key={item.id} title={item.name}></Tabs.Tab>
          ))}
        </Tabs>
      </div>
      {/* 导航内容展示 */}
      <div className='nav_desc'>
        <h1>{currRow.title}</h1>
        <p className='tag'>
          {currRow.iconList.map((item, index) => (
            <span key={index}>
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              {item.text}
            </span>
          ))}
          {currRow.type == 1 ? (
            <>
              <b className='line1' style={{ width: '1px' }}></b>
              <b className='line2' style={{ width: '1px' }}></b>
              <b style={{ width: '1px' }} className='line3'></b>
            </>
          ) : null}
        </p>
        {currRow.litterTitle ? (
          <p className='litter'>{currRow.litterTitle}</p>
        ) : null}
        <img src={currRow.url} alt='' className='thum_opacity' />
        <button className='mainBtn' onClick={toKnowMore}>
          了解更多
          <svg className='icon' aria-hidden='true'>
            <use xlinkHref='#wt-woketech_icon_arrow_right'></use>
          </svg>
        </button>
      </div>
    </div>
  )
}
export default FunTroduction
