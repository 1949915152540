import { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import "./index.scss"
const Pagination = (props) => {
    const [maxPage,setMax] = useState(false)
    const { pageNum, pageSize ,total,handleNumChange} = props;
    useEffect(()=>{
        let maxPage = parseInt(total/pageSize);;
        if(total%pageSize!=0){
            maxPage = maxPage+1;
        }
        setMax(maxPage);
    },[pageNum,pageSize])
    const toPrev = ()=>{
        //获取当前页码
        handleNumChange(pageNum-1)
    }
    const toNext = ()=>{
        handleNumChange(pageNum+1)
    }
    return (
        <div className="pagination">
            <div className="make_page">
                <div className="prev">
                    <button disabled={pageNum==1} onClick={toPrev}>上一页</button>
                </div>
                <div className="total"><b>{pageNum}</b><span>/</span>{maxPage}</div>
                <div className="next">
                    <button disabled={maxPage==pageNum}  onClick={toNext}>下一页</button>
                </div>
            </div>
        </div>
    )
}
Pagination.defaultProps={
    pageNum:1,
    pageSize:10,
    total:0,
}
Pagination.propTypes = {
    pageNum:PropTypes.number,
    pageSize:PropTypes.number,
    total:PropTypes.number,
    handleNumChange:PropTypes.func.isRequired
}
export default Pagination;