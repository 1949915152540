import React from 'react';
import ReactDOM from 'react-dom';

import Router from './router'

import "@/styles/common.scss"
import "amfe-flexible"

ReactDOM.render(
        < Router />,
    document.getElementById('root')
);