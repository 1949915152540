import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import './index.scss'
const navList = [
  { name: '解决方案', path: '/solution' },
  { name: '合作伙伴', path: '/partner' },
  // { name: '资讯中心', path: '/news' },
  // { name: '关于我们', path: '/aboutUs' },
]
const children = [
  { name: '组织人事', path: '/product/personnel' },
  { name: '智能排班', path: '/product/scheduling' },
  { name: 'OA管理', path: '/product/manage' },
  { name: '智慧考勤', path: '/product/attendance' },
  { name: '薪资管理', path: '/product/payment' },
  { name: '企业培训', path: '/product/school' },
  { name: '图钉签到', path: '/product/isv' },
]
const Menu = (props) => {
  const [isShowMenu, setIsShowMenu] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { close, type } = props
  const jump = (row, type = 1) => {
    // navigate(row.path);
    if (type == 1) {
      setIsShowMenu(!isShowMenu)
    }
    window.location.href = row.path
    if (!close) return //非弹窗情况
    close()
  }
  return (
    <div className='menu'>
      <ul>
        <li
          onClick={() => jump({ path: '/' }, 2)}
          className={'/' == pathname ? 'active' : ''}
        >
          回到首页
          <svg className='icon' aria-hidden='true'>
            <use xlinkHref='#wt-woketech_icon_arrow'></use>
          </svg>
        </li>
        <div>
          <li
            onClick={() => setIsShowMenu(!isShowMenu)}
            className={pathname.indexOf('product') > -1 ? 'active' : ''}
          >
            产品与服务
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#wt-woketech_icon_arrow'></use>
            </svg>
          </li>
          {isShowMenu ? (
            <div className='down_menu'>
              {children.map((item, index) => (
                <span
                  key={index}
                  onClick={() => jump(item)}
                  className={item.path == pathname ? 'active' : ''}
                >
                  {item.name}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        {navList.map((item, index) => (
          <li
            key={index}
            onClick={() => jump(item, 2)}
            className={[
              item.path == pathname ? 'active' : '',
              index == 3 && type == 'normal' ? 'noBorder' : '',
            ].join(' ')}
          >
            {item.name}
            <svg className='icon' aria-hidden='true'>
              <use xlinkHref='#wt-woketech_icon_arrow'></use>
            </svg>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default Menu
