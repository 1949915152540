import React, { useState } from 'react'
import { request } from '@/utils/request'
import HomePartner from '@/pages/home/components/Partner'
import { subAgent, subService } from '@/utils/api'
import Agent from './components/Agent'
import Service from './components/Service'
import { Toast } from 'antd-mobile'
import './index.scss'

const navList = ['代理商', '服务商']
const serviceList = [
  { text: '申请<br/>加入', icon: 'wt-partner_dls_icon10' },
  { text: '资质<br/>考察', icon: 'wt-partner_dls_icon11' },
  { text: '合作<br/>洽谈', icon: 'wt-partner_dls_icon12' },
  { text: '签约<br/>联盟', icon: 'wt-partner_dls_icon13' },
]
const agentList = [
  { text: '信息<br/>填写', icon: 'wt-partner_fws_icon06' },
  { text: '资质<br/>审核', icon: 'wt-partner_fws_icon07' },
  { text: '合作<br/>洽谈', icon: 'wt-partner_fws_icon08' },
  { text: '达成<br/>合作', icon: 'wt-partner_fws_icon09' },
]
const Partner = () => {
  const [type, setType] = useState(0)
  let childRef = React.createRef()
  const submit = (data, type = 'agent') => {
    data['channel'] = 1
    request(type == 'agent' ? subAgent : subService, data).then((result) => {
      if (result.statusCode == 'B00000') {
        Toast.show('您的资料已成功提交！')
        //清空输入框
        childRef.current.resetForm()
      } else {
        Toast.show(result.message)
      }
    })
  }
  return (
    <div className='partner'>
      <div className='part_one'>
        <h1>梧桐People打造“召唤联盟”</h1>
        <p>
          诚邀合作伙伴加入 <br />
          一起共拓HRSaaS市场蓝海
        </p>
        <img
          src='image_m/parnter/h5_partner_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 代理商/服务商切换 */}
      <div className='toggle'>
        <ul style={{ border: '1px solid #ccc' }}>
          {navList.map((item, index) => (
            <li
              key={index}
              onClick={() => setType(index)}
              className={`${type == index ? 'active' : ''}`}
            >
              {item}
            </li>
          ))}
        </ul>
      </div>
      {type ? (
        <Service onRef={childRef} submitCallback={submit} />
      ) : (
        <Agent onRef={childRef} submitCallback={submit} />
      )}
      {/* 公共底部合作信息 */}
      {/* 合作流程 */}
      <div className='part_four'>
        <h1>合作流程</h1>
        <ul>
          {(type == 1 ? agentList : serviceList).map((item, index) => (
            <li key={index}>
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
            </li>
          ))}
        </ul>
      </div>
      {/* 现有合作伙伴 */}
      <HomePartner bgColor='#F8FAFF' />
    </div>
  )
}
export default Partner
