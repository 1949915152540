import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Menu from '@/components/Menu'

import './index.scss'
const Header = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [showFws, setShowFws] = useState(false)
  const location = useLocation()
  const hanleToggle = () => {
    setShowMenu(!showMenu)
  }
  const jump = () => {
    window.location.href = '/'
  }
  useEffect(() => {
    if (location.pathname.includes('isv')) {
      setShowFws(true)
    }
  }, [])
  return (
    <div className='header'>
      <div className='nav_title'>
        <div className='left_logo'>
          <img
            src='/image_m/user_login_logo.png'
            alt='logo'
            onClick={jump}
            className='logo'
          />
          {showFws && (
            <img
              src='/image_m/product/isv/img_qywxfws.png'
              alt='logo'
              className='logo_fws'
              onClick={() => {
                window.location.href = 'https://open.work.weixin.qq.com/'
              }}
            />
          )}
        </div>
        <svg className='icon' aria-hidden='true' onClick={hanleToggle}>
          <use xlinkHref='#wt-woketech_icon_menu'></use>
        </svg>
      </div>
      {/* 弹出框 */}
      {showMenu ? (
        <div className='modal'>
          <div className='modal_btn clearfix'>
            <img src='/image_m/user_login_logo.png' alt='logo' onClick={jump} />
            <svg className='icon' aria-hidden='true' onClick={hanleToggle}>
              <use xlinkHref='#wt-woketech_icon_close'></use>
            </svg>
          </div>
          <Menu close={hanleToggle} type='modal' />
        </div>
      ) : null}
    </div>
  )
}

export default Header
