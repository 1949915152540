import { useState } from 'react'
import Menu from '@/components/Menu'
import ImgModule from '@/components/Modal/ImgModal'

import './index.scss'

const Footer = () => {
  const [isShowModal, setIsShowModal] = useState(false)
  const [erType, setUrl] = useState(1)
  const handleToggle = () => {
    setIsShowModal(!isShowModal)
  }
  const handleEr = (type) => {
    setUrl(type)
    setIsShowModal(!isShowModal)
  }
  return (
    <div className='footer'>
      {/* 导航栏 */}
      <Menu type='normal' />
      {/* 备案信息栏 */}
      <div className='record'>
        <div className='record_top'>
          <img
            className='logo'
            src='/image_m/user_login_logo_footer.png'
            alt=''
          />
          <p>
            浙江省杭州市萧山区平澜路299号 <br />
            浙江商会大厦21层
          </p>
          {/* 微信、微博图标 */}
          <div className='share'>
            <svg
              className='icon'
              aria-hidden='true'
              onClick={() => handleEr(1)}
            >
              <use xlinkHref='#wt-h5_index_icon_01'></use>
            </svg>
            <svg
              className='icon'
              aria-hidden='true'
              onClick={() => handleEr(2)}
            >
              <use xlinkHref='#wt-h5_index_icon_02'></use>
            </svg>
          </div>
        </div>
        <div className='line'></div>
        <div className='record_bottom'>
          <p>
            @Copyright 2024 杭州唤行科技有限公司 <br />
            浙ICP备2021010503号-1
          </p>
        </div>
      </div>
      {/* 弹出框 */}
      {isShowModal ? (
        <ImgModule close={handleToggle} name={erType}></ImgModule>
      ) : null}
    </div>
  )
}
export default Footer
