import ApplyUse from '@/components/ApplyUse'

const list = [
  { name: '一键排班', icon: 'wt-product_pb_icon01' },
  { name: '智能排班', icon: 'wt-product_pb_icon02' },
  { name: '排班管理', icon: 'wt-product_pb_icon03' },
  { name: '经营预测', icon: 'wt-product_pb_icon04' },
  { name: '劳力支援', icon: 'wt-product_pb_icon05' },
  { name: '排班分析', icon: 'wt-product_pb_icon06' },
]
const Scheduling = () => {
  return (
    <div className='pro_detail'>
      <div className='part_one'>
        <h1>
          智能 <span>排班</span>
        </h1>
        <p>AI技术精确预测工时，合理规划劳动力，帮</p>
        <p>助企业控制劳动力成本，提高用工效率</p>
        <img
          src='/image_m/product/scheduling/h5_pb_chahua_01.png'
          alt=''
          className='thum_opacity'
        />
      </div>
      {/* 功能介绍 */}
      <div className='part_two'>
        <h1 className='title'>功能介绍</h1>
        <ul>
          {list.map((item, index) => (
            <li key={index}>
              <svg className='icon' aria-hidden='true'>
                <use xlinkHref={`#${item.icon}`}></use>
              </svg>
              <p>{item.name}</p>
            </li>
          ))}
        </ul>
      </div>
      {/* 闪电排班 */}
      <div className='part_three'>
        <h1 className='title'>闪电排班</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>多元组件，灵活拖拽，后台操作便捷</p>
          </li>
          <li>
            <b></b>
            <p>支持按人/按组排班，一键生成班表，一步到位</p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/scheduling/h5_pb_chahua_02.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 多维数据采集 AI预测 */}
      <div className='part_four'>
        <h1 className='title'>多维数据采集 AI预测</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>
              基于历史数据/天气状况/节假情况等，推算客流状况，给到准确用工建议
            </p>
          </li>
          <li>
            <b></b>
            <p>
              根据行业政策、客流预判、员工偏好等，为企业提供多套排班方案供选择
            </p>
          </li>
          <li>
            <b></b>
            <p>
              结合用工规范、员工疲劳度、经营任务等，进行合理合规的排班规划，最大化用工效率
            </p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/scheduling/h5_pb_chahua_03.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
      {/* 按需调配劳动力 */}
      <div className='part_three'>
        <h1 className='title'>按需调配劳动力</h1>
        <ul className='text_desc'>
          <li>
            <b></b>
            <p>
              外置硬件监测与人力算法模型，实时在线运转，及时发现用工缺口和需求，给到解决方案
            </p>
          </li>
          <li>
            <b></b>
            <p>
              支持在线发布人员缺口需求，一键发布抢班，同城跨店支援、闪电直招
            </p>
          </li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <img
            src='/image_m/product/scheduling/h5_pb_chahua_04.png'
            alt=''
            className='thum_opacity'
          />
          <ApplyUse></ApplyUse>
        </div>
      </div>
    </div>
  )
}
export default Scheduling
